import {createApp} from 'vue'
import Antd from 'ant-design-vue'
import App from './MainLayout.vue'
import './registerServiceWorker'
import {router} from "@/app";
import 'ant-design-vue/dist/reset.css'
import {publish, reg} from "@/lib/session";
import emit from "@/lib/emit";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import '@/lib/array.extension'
import {setupMock} from "@/lib/util";
import VueLazyload from "vue-lazyload";
import * as Sentry from "@sentry/vue";

setupMock()

dayjs.extend(duration)
const app = createApp(App).use(Antd).use(VueLazyload, {
    preLoad: 1.3,
    loading: require('@/assets/image/loading_template.gif'),
    attempt: 1
})

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        app,
        dsn: "https://9c6e5e2a35226dea4ed3e80c03507ef6@o1122643.ingest.us.sentry.io/4507915455823872",
        integrations: [
            Sentry.browserTracingIntegration({router}),
            Sentry.replayIntegration(),
            Sentry.replayCanvasIntegration()
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/api\.activity\.djhdb\.cn\/pc/],
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

reg(() => app.use(router).mount('#app'))

document.addEventListener('click', (evt: MouseEvent) => {
    const el = evt.target as HTMLElement
    let c = `<${el.tagName} `
    el.getAttributeNames().forEach((name: string) => c += `${name}="${el.getAttribute(name)}" `)
    if (el.innerText === undefined) c += `</${el.tagName}>`
    else c += `>${el.innerText.substring(0, 30) == '' ? el.innerHTML.substring(30) : el.innerText.substring(0, 30).replace(/[\r\n]/g, "")}</${el.tagName}>`
    publish('click', c)
}, false)

window.addEventListener('resize', (evt) => emit.emit('window.resize', evt))