import {RouteRecordRaw} from "vue-router";
import NotFound from "@/app/exception/NotFound.vue";
import LogoView from "@/components/LogoView.vue";

export default <Array<RouteRecordRaw>>[
    {
        path: '/404',
        name: 'notfound',
        meta: {requiresAuth: false},
        components: {
            default: NotFound,
            tabBar: LogoView
        }
    },
]
