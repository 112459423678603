<template>
  <div class="barrage">
    <div><img src="https://b.s.mywsy.cn/media/image/default_header.jpg-sq.90" alt="head img"/><span>顾客名称，已购买</span>
    </div>
    <div><img src="https://b.s.mywsy.cn/media/image/default_header.jpg-sq.90" alt="head img"/><span>顾客名称，已购买</span>
    </div>
    <div><img src="https://b.s.mywsy.cn/media/image/default_header.jpg-sq.90" alt="head img"/><span>顾客名称，已购买</span>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="sass">
.barrage
  z-index: 1
  position: absolute
  left: -100%
  top: 0
  right: -200%
  margin: 100px auto 0
  overflow: hidden

  > div
    float: right
    display: flex
    align-items: center
    height: 30px
    line-height: 30px
    background: rgba(0, 0, 0, .4)
    border-radius: 15px
    padding: 0 10px 0 3px
    width: fit-content
    animation: scrollTo linear 13s infinite forwards

    &:nth-child(1)
      margin: 0 0 0 140px
    &:nth-child(2)
      margin: 40px 0 0 120px

    > img
      width: 25px
      height: 25px
      border-radius: 50%
      margin-right: 5px

    > span
      color: white
      font-size: 12px

  @keyframes scrollTo
    to
      transform: translateX(-1200px)
</style>