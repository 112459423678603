import {UnwrapRef, watch} from "vue";
import {IModule} from "src/app/editor/modules";
import {DefineComponent} from "@vue/runtime-core";
import GroupPreview from "@/app/editor/modules/group/v1/GroupPreview.vue";
import GroupSetup from "@/app/editor/modules/group/v1/GroupSetup.vue";
import {IActivityGift, IActivityStatus} from "@/app/editor/types";
import {editor} from "@/app/editor";

export interface IGroupConfigStepItem {
    category: '组团优惠' | '全员福利' | '团长福利' | '未成团优惠'
    gift: IActivityGift | null
    title: string
    value: number
    amount: number
}

export interface IGroupStep {
    group_size: number
    price: number
    total_value: number
    items: IGroupConfigStepItem[]
}

export interface IGroupGoodsInfo {
    stock: number
    sell_price: number
    total_value: number
    leader_free: boolean
}

export interface IGroupModuleProps {
    buy_bottom_text: string
    customer_buy_limit: number
    goods_validity_mons: number
    is_only_regular_customer_can_be_leader: boolean
    is_only_freshman_can_be_join: boolean
    group_time_limit_hours: number
    group_info_picture: string[]
}

export interface IGroupModuleConfig {
    type: '普通团' | '价格阶梯团' | '商品阶梯团'
    goods: IGroupGoodsInfo
    fail_items: IGroupConfigStepItem[]
    steps: IGroupStep[]
    props: IGroupModuleProps
}

export interface IGroupModule extends IModule {
    config: UnwrapRef<IGroupModuleConfig>
}

const categories = ['组团优惠', '全员福利', '团长福利']

export class GroupModuleV1 implements IGroupModule {
    readonly i: number
    readonly module = 'group';
    readonly version = 1;
    readonly title = '团购设置';
    readonly icon = 'fuwu';
    readonly preview: DefineComponent;
    readonly set_up: DefineComponent;
    config: UnwrapRef<IGroupModuleConfig>;

    constructor(i: number, c: UnwrapRef<IGroupModuleConfig>, s: IActivityStatus) {
        this.i = i
        this.preview = GroupPreview as DefineComponent
        this.set_up = GroupSetup as DefineComponent
        this.config = c

        // 自动更正数据
        if (!this.config.type) this.config.type = '普通团'
        if (!this.config.goods) this.config.goods = {
            sell_price: 0,
            total_value: 0,
            stock: 0,
            leader_free: false
        }
        if (!this.config.goods.total_value) this.config.goods.total_value = 0
        if (!this.config.steps || this.config.steps.length == 0) this.config.steps = [{
            group_size: 2,
            price: 0,
            items: [],
            total_value: 0
        }]
        if (!this.config.fail_items) this.config.fail_items = []
        for (let i = 0; i < this.config.steps.length; i++) {
            if (this.config.steps[i].price < this.config.goods.sell_price) this.config.steps[i].price = this.config.goods.sell_price
            if (i > 0) {
                if (this.config.steps[i].price > this.config.steps[i - 1].price) this.config.steps[i].price = this.config.steps[i - 1].price
                if (this.config.steps[i].group_size <= this.config.steps[i - 1].group_size) this.config.steps[i].group_size = this.config.steps[i - 1].group_size + 2
            }
        }
        this.config.steps.forEach(s => s.items.sort((a, b) => categories.indexOf(a.category) - categories.indexOf(b.category)))

        if (!this.config.props) this.config.props = {
            goods_validity_mons: 30,
            group_time_limit_hours: 24,
            buy_bottom_text: this.config.type == '价格阶梯团' ? '立即开团(定金)' : "立即开团",
            customer_buy_limit: 1,
            is_only_regular_customer_can_be_leader: false,
            is_only_freshman_can_be_join: false,
            group_info_picture: []
        }
        if (!this.config.props.group_info_picture) this.config.props.group_info_picture = []
        // 公共属性绑定
        s.buy_button_text = this.config.props.buy_bottom_text
        s.sell_price = this.config.goods.sell_price
        s.rules[i] = [
            `每人限购${this.config.props.customer_buy_limit}笔`,
            `购买后${this.config.props.goods_validity_mons}个月内到店消费有效`,
            `开团后${this.config.props.group_time_limit_hours}小时内不成团，视为未成团`
        ]
        if (this.config.props.is_only_regular_customer_can_be_leader) s.rules[i].push('仅允许老会员开团')
        if (this.config.props.is_only_freshman_can_be_join) s.rules[i].push('仅允许新客参团')
        watch(this.config, c => {
            let totalValue = 0
            for (let j = 0; j < c.steps.length; j++) {
                this.config.steps[j].total_value = c.steps[j].items.reduce((n, i) => n + i.value, 0)
                totalValue = this.config.steps[j].total_value > totalValue ? this.config.steps[j].total_value : totalValue
                // console.log('total_value', this.config.goods.total_value)
            }
            setTimeout(() => {
                this.config.goods.total_value = totalValue
            }, 10)
            this.config.steps.forEach(s => s.items.sort((a, b) => categories.indexOf(a.category) - categories.indexOf(b.category)))
            if (editor.activity) editor.activity.data.title = editor.activity.previewTitle(editor.activity.data.titles.find(t => t.title_id == editor.activity?.status.title_id)?.title)
            s.buy_button_text = c.props.buy_bottom_text
            s.sell_price = c.goods.sell_price
            s.rules[i] = [
                `每人限购${c.props.customer_buy_limit}笔`,
                `购买后${c.props.goods_validity_mons}个月内到店消费有效`,
                `开团后${c.props.group_time_limit_hours}小时内不成团，视为未成团`
            ]
            if (c.props.is_only_regular_customer_can_be_leader) s.rules[i].push('仅允许老会员开团')
            if (c.props.is_only_freshman_can_be_join) s.rules[i].push('仅允许新客参团')
        })
    }

    validate(): Error | null {
        // 公共数据
        if (!this.config.goods.sell_price) return new Error(this.config.type == '价格阶梯团' ? '定金不能为空' : '团购价不能为空')
        if (!this.config.goods.stock) return new Error('库存不能为空')
        if (this.config.goods.sell_price <= 0) return new Error(this.config.type == '价格阶梯团' ? '定金至少0.1元' : '团购价至少0.1元')
        if (this.config.goods.stock < 1) return new Error('库存至少为1')
        if (this.config.steps.length == 0) return new Error('阶梯不能为空')
        if (this.config.type != '普通团' && this.config.steps.length < 2) return new Error('阶梯团至少要2个阶梯')
        for (let j = 0; j < this.config.steps.length; j++) {
            const s = this.config.steps[j]
            if (this.config.type == '价格阶梯团' && s.price < this.config.goods.sell_price) return new Error('团购价不得高于定金')
            if (j > 0) {
                const l = this.config.steps[j - 1]
                if (l.group_size > s.group_size) return new Error('成团人数必须高于上一阶梯')
                if (this.config.type == '价格阶梯团' && l.price <= s.price) return new Error('团购价必须逐级递减')
            }
            if (s.items.length == 0) {
                return new Error(`${s.group_size}人团，礼包不能为空`)
            }
            s.items.forEach(i => {
                if (i.title == '') return new Error(`${s.group_size}人团，礼包标题不能为空`)
                if (i.value == 0) return new Error(`${s.group_size}人团，礼包价值不能为0`)
            })
        }
        if (this.config.fail_items.length == 0) return new Error('未成团设置不能为空')
        return null
    }
}