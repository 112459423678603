import {UnwrapRef} from "vue";
import {DefineComponent} from "@vue/runtime-core";
import {IModule} from "src/app/editor/modules";
import IntroductionPreview from "@/app/editor/modules/introduction/v1/IntroductionPreview.vue";
import IntroductionSetup from "@/app/editor/modules/introduction/v1/IntroductionSetup.vue";
import {IActivityStatus} from "@/app/editor/types";
import {editor} from "@/app/editor";

export interface IIntroductionConfig {
    notice: string[]
    shop_style: number
    shop_content: string
    content_images: string[]
}

export interface IIntroductionSection extends IModule {
    config: UnwrapRef<IIntroductionConfig>
}

export class IntroductionSectionV1 implements IIntroductionSection {
    readonly i: number
    readonly module = 'introduction';
    readonly version = 1;
    readonly title = '内容设置';
    readonly icon = 'jie_shao';
    readonly preview: DefineComponent;
    readonly set_up: DefineComponent;
    config: UnwrapRef<IIntroductionConfig>;

    constructor(i: number, c: UnwrapRef<IIntroductionConfig>, s: IActivityStatus) {
        this.i = i
        this.preview = IntroductionPreview as DefineComponent
        this.set_up = IntroductionSetup as DefineComponent
        this.config = c
        // 自动更正数据
        if (!this.config.content_images) this.config.content_images = []
        if (!this.config.notice) this.config.notice = []
        if (!this.config.shop_style) this.config.shop_style = 0
        if (!this.config.shop_content) this.config.shop_content = ''

            setTimeout(() => {
            if (!this.config.shop_content) this.config.shop_content = editor.activity?.data.introduction_contents.first()?.content ?? ''
        }, 1000)
    }

    validate(): Error | null {
        return null;
    }
}