import {IModule} from "@/app/editor/modules";
import {UnwrapRef} from "vue";
import {DefineComponent} from "@vue/runtime-core";
import {IActivityGift, IActivityStatus} from "@/app/editor/types";
import ForwardPreview from "@/app/editor/modules/forward/v1/ForwardPreview.vue";
import ForwardSetup from "@/app/editor/modules/forward/v1/ForwardSetup.vue";

export interface IShareLimit {
    enable: boolean
    amount: number
}
export interface IForwardConfig {
    share_timeline_limit: IShareLimit
    timeline_like_limit: IShareLimit
    share_local_group_limit: IShareLimit
    complete_count: number
    gifts: IActivityGift[]
}

export interface IForwardModule extends IModule {
    config: UnwrapRef<IForwardConfig>
}

export class ForwardModuleV1 implements IForwardModule {
    readonly i: number
    readonly module = 'forward';
    readonly version = 1;
    readonly title = '转发设置';
    readonly icon = 'fen_xiang';
    readonly preview: DefineComponent;
    readonly set_up: DefineComponent;

    config: UnwrapRef<IForwardConfig>;

    constructor(i: number, c: UnwrapRef<IForwardConfig>, s: IActivityStatus) {
        this.i = i
        this.preview = ForwardPreview as DefineComponent
        this.set_up = ForwardSetup as DefineComponent
        this.config = c

        if (!this.config.share_timeline_limit) this.config.share_timeline_limit = {amount: 3, enable: false}
        if (!this.config.timeline_like_limit) this.config.timeline_like_limit = {amount: 50, enable: false}
        if (!this.config.share_local_group_limit) this.config.share_local_group_limit = {amount: 5, enable: false}
        if (!c.complete_count) this.config.complete_count = 0
        if (!c.gifts) this.config.gifts = []
    }

    validate(): Error | null {
        return null;
    }
}