<template>
  <a-spin :spinning="false">
    <slot/>
  </a-spin>
</template>

<script setup lang="ts">

</script>

<style scoped lang="sass">
.ant-spin-nested-loading
  padding: 24px 24px 32px 24px
  border-radius: 10px
  background-color: #fff
  margin-bottom: 16px
</style>