import {reactive, UnwrapRef, watch} from "vue";
import {DefineComponent} from "@vue/runtime-core";
import BasicStylePreview from "@/app/editor/modules/basic/v1/BasicStylePreview.vue";
import BasicStyleSetup from "@/app/editor/modules/basic/v1/BasicStyleSetup.vue";
import {IModule} from "@/app/editor/modules";
import {Json, req} from "@/lib/util";
import {editor} from "@/app/editor";
import {IActivityStatus, IThemeProps} from "@/app/editor/types";
import {Random} from "mockjs";


export interface IBasicVirtual {
    view: number
    sign: number
    share: number
    pay: number
}

export interface IBasicProps {
    show_barrage: boolean
    show_statistics: boolean
    virtual: IBasicVirtual
    is_show_pay_history: boolean
}

export interface IImageTemplateDrawOption {
    image_template_id: string
    id: number
    length_max: number
    length_min: number
    text: string
    title: string
}


export interface IBasicConfig {
    music_url: string
    themes: string[]
    theme: IThemeProps
    title_id: string
    props: IBasicProps
}

export interface IBasicModule extends IModule {
    config: UnwrapRef<IBasicConfig>
}


export interface IPrivateData {
    audio_url: string
    audio_status: boolean
}

interface IMusicDataCategoryItem {
    name: string
    url: string
}

interface IMusicDataCategory {
    name: string
    data: IMusicDataCategoryItem[]
}

export interface IMusicData {
    child: IMusicDataCategory
    classical: IMusicDataCategory
    festival: IMusicDataCategory
    hot: IMusicDataCategory
    natural: IMusicDataCategory
    piano: IMusicDataCategory
    wangluo: IMusicDataCategory
}


export class BasicModuleV1 implements IBasicModule {
    readonly i: number
    readonly module = 'basic';
    readonly version = 1;
    readonly title = '基础设置';
    readonly icon = 'she_zhi';
    readonly preview: DefineComponent;
    readonly set_up: DefineComponent;
    config: UnwrapRef<IBasicConfig>;
    data: UnwrapRef<IPrivateData>
    audio: HTMLAudioElement
    readonly musicLibrary: IMusicData
    private optionCache: { [p: string]: { [p: string]: IImageTemplateDrawOption[] } } = {}

    constructor(i: number, c: UnwrapRef<IBasicConfig>, s: IActivityStatus) {
        this.i = i
        this.preview = BasicStylePreview as DefineComponent
        this.set_up = BasicStyleSetup as DefineComponent
        this.config = c

        // 自动更正数据
        if (!this.config.props) {
            this.config.props = {
                show_barrage: true,
                show_statistics: true,
                virtual: {view: 0, sign: 0, share: 0, pay: 0},
                is_show_pay_history: true
            }
            this.config.props.virtual.sign = Math.round(Random.integer(100, 200))
            this.config.props.virtual.view = Math.round(this.config.props.virtual.sign * 12.32)
            this.config.props.virtual.share = Math.round(this.config.props.virtual.sign * 2.56)
            this.config.props.virtual.pay = Math.round(this.config.props.virtual.sign * 0.84)
        }
        if (!this.config.music_url) this.config.music_url = ''
        this.data = reactive({
            audio_status: false,
            audio_url: ''
        })
        if (!this.config.themes) this.config.themes = []
        if (!this.config.theme) this.config.theme = {
            applet_poster_id: "",
            applet_thumb_version: 1,
            theme_id: '',
            theme_options: {}
        }
        if (!this.config.theme.applet_thumb_version) this.config.theme.applet_thumb_version = 1
        if (!this.config.title_id) this.config.title_id = ''

        // 活动音乐
        this.audio = new Audio()
        this.audio.onplay = () => this.data.audio_status = true
        this.audio.onpause = () => this.data.audio_status = false
        this.audio.onended = () => this.data.audio_status = false
        this.musicLibrary = require('@/assets/music/music.json')

        s.theme_props = this.config.theme
        s.theme_ids = this.config.themes
        s.title_id = this.config.title_id
        // 监听
        watch(() => this.config.title_id, (id => {
            if (!editor.activity) return
            const ts = editor.activity.data.titles.find(t => t.title_id == id)?.title
            editor.activity.data.title = ts ? editor.activity.previewTitle(ts) : '未设置'
        }))
    }

    validate(): Error | null {
        if (!this.config.theme.theme_id) return new Error('主题没有选择')
        if (!this.config.theme.applet_thumb_version) return new Error('没有指定分享卡片封面')
        if (!this.config.theme.applet_poster_id) return new Error('没有指定分享海报')
        if (!this.config.title_id) return new Error('没有设置标题')
        return null;
    }

    musicName(mUrl: string): string {
        for (const k in this.musicLibrary) {
            for (const item of ((this.musicLibrary as unknown as Json)[k] as IMusicDataCategory).data) {
                if (item.url == mUrl) return item.name
            }
        }
        return mUrl ? '自定义音乐' : ''
    }

    themeSwitch(themeId: string, fn: () => void) {
        if (themeId in this.optionCache) {
            this.mergeThemeOption(themeId, this.optionCache[themeId])
            fn()
            return
        }
        req({
            url: 'activity/theme/options',
            params: {theme_id: themeId},
            success: (rs: { [p: string]: IImageTemplateDrawOption[] }) => {
                this.mergeThemeOption(themeId, rs)
            },
            complete: fn
        })
    }

    private mergeThemeOption(themeId: string, rs: { [p: string]: IImageTemplateDrawOption[] }) {
        // 自动合并本地参数
        for (const k in rs) {
            for (let i = 0; i < rs[k].length; i++) {
                rs[k][i].text = this.getOption(rs[k][i].title) ?? rs[k][i].text
                // console.log('set rs', k, i, rs[k][i].title, ':', this.getOption(rs[k][i].title))
            }
        }
        this.optionCache[themeId] = rs
        this.config.theme.theme_options = rs
        this.config.theme.theme_id = themeId
        this.config.theme.applet_thumb_version = 1
        this.config.theme.applet_poster_id = editor.activity?.data.themes.find(t => t.theme_id == themeId)?.applet_poster_image_template_ids.first() ?? ''

        editor.activity?.refresh()
        editor.activity?.loadShowThemes()
    }

    private getOption(title: string): string | null {
        for (const k in this.config.theme.theme_options) {
            for (let i = 0; i < this.config.theme.theme_options[k].length; i++) {
                if (this.config.theme.theme_options[k][i].title == title) return this.config.theme.theme_options[k][i].text
            }
        }
        return null
    }

    onOptionChange(evt: InputEvent, imageId: string, k: number) {
        this.config.theme.theme_options[imageId][k].text = evt.data ?? ''
        for (const nk in this.config.theme.theme_options) {
            for (let i = 0; i < this.config.theme.theme_options[nk].length; i++) {
                if (this.config.theme.theme_options[nk][i].title == this.config.theme.theme_options[imageId][k].title) this.config.theme.theme_options[nk][i].text = evt.data ?? ''
            }
        }
        editor.activity?.refresh()
    }
}