<template>
  <a-col :span="20">
    <slot/>
  </a-col>
</template>

<script setup lang="ts">

</script>

<style scoped lang="sass">

</style>