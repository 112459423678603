import {ITheme} from "@/app/editor/theme";
import {ITitle} from "@/app/editor/title";
import {Dayjs} from "dayjs";

export interface IModuleData {
    i: number
    module: 'basic' | 'group' | 'shop' | 'introduction' | 'forward' | 'recommend'
    version: number
    config?: unknown
}

export interface IImageTemplateDrawOption {
    image_template_id: string
    id: number
    length_max: number
    length_min: number
    text: string
    title: string
}

export interface IActivityGift {
    gift_id: string
    tags: string[]
    name: string
    thumb_url: string
    image_url: string
    front_value: number
    cost_price_range: number[]
    taobao_keyword: string
    pdd_keyword: string
}

export interface IActivityPicture {
    picture_id: string
    tags: string[]
    url: string
}

export interface IActivityShop {
    phone_number: string
    sid: number
    icon: string
    shop_name: string
    tel: string
    area: string
    addr: string
    coordinate: number[]
}

export interface IAuthShop extends IActivityShop {
    expire_days: number
}

export interface IActivityIntroductionContent {
    content: string
}

export interface IApplyAuthorization {
    apply_id: string
    apply_phone_number: string
    accept_at: string | null
    expire_days: number
    auth_count: number
}

export interface IActivityData {
    activity_id: string
    draft_id: string
    template_id: string
    tags: string[]
    exp_package_id: string
    title: string | null
    start_at: Date | null
    end_at: Date | null
    sid: number | null
    modules: IModuleData[]
    themes: ITheme[]
    titles: ITitle[]
    gifts: IActivityGift[]
    pictures: IActivityPicture[]
    shops: IActivityShop[]
    auth_shops: IAuthShop[]
    apply_authorizations: IApplyAuthorization[]
    introduction_contents: IActivityIntroductionContent[]
    is_active?: boolean
    create_at: string
}

export interface IActivityStatus {
    is_distribution: boolean
    distribution_list: 'hide' | 'count' | 'sum'
    buy_button_text: string
    date_range: Dayjs[]
    theme_props: IThemeProps
    theme_ids: string[]
    title_id: string
    sell_price: number
    rules: { [type: number]: string[] }
    shop_name: string
}

export interface IThemeProps {
    theme_id: string
    theme_options: { [key: string]: IImageTemplateDrawOption[] }
    applet_thumb_version: number
    applet_poster_id: string
}

export interface IThemeImagePreview {
    imageId: string
    version: number
    src: string
    code: number
    loading: boolean
}

export const variables: any = {
    '预约热线': {module: {shop: {1: ['shop', 'tel']}}},
    '店名': {module: {shop: {1: ['shop', 'shop_name']}}},
    '拼团价': {module: {group: {1: ['goods', 'sell_price']}}},
    '拼团类型': {module: {group: {1: ['type']}}},
    '商品价值': {module: {group: {1: ['goods', 'total_value']}}},
    '分享人昵称': {wx: ['me', 'nick_name']},
    '分享人头像': {wx: ['me', 'head_img_url']},
}
