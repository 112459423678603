<template>
  <router-link to="/" class="logo">
    <a-avatar :src="require('@/assets/image/logo.png')" size="large"/>
    <a-flex align="center" gap="28">
      <div>店家活动宝</div>
      <div>店家管理后台</div>
    </a-flex>
  </router-link>
</template>

<script setup lang="ts">

</script>

<style scoped lang="sass">

a
  text-decoration: none

.logo
  width: 300px
  display: flex
  align-items: center
  justify-content: center
  gap: 16px

  > .ant-flex
    > div

      &:first-child
        font-size: 20px
        color: rgb(0, 0, 0)
        position: relative

        &:after
          position: absolute
          top: 4px
          right: -14px
          width: 1px
          height: 16px
          background-color: #e5e5e5
          content: ""

      &:last-child
        font-size: 16px
        color: rgb(0, 0, 0)
        font-weight: bolder
</style>