<template>
  <a-row :gutter="[18,22]">
    <slot/>
  </a-row>
</template>

<script setup lang="ts">

</script>

<style scoped lang="sass">

</style>