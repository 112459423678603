import {openobserveRum} from "@openobserve/browser-rum";
import {openobserveLogs} from "@openobserve/browser-logs";

const options = {
    clientToken: 'rumWSAU7bVU9mT5EflD',
    applicationId: 'activity',
    site: 'analyze.log.guandb.cn',
    service: 'activity',
    env: process.env.NODE_ENV,
    version: process.env.VERSION,
    organizationIdentifier: 'djhdb',
    insecureHTTP: false,
    apiVersion: 'v1',
}

export function InitOpenObserve(id: string, name: string, email: string) {
    openobserveRum.init({
        applicationId: options.applicationId, // required, any string identifying your application
        clientToken: options.clientToken,
        site: options.site,
        organizationIdentifier: options.organizationIdentifier,
        service: options.service,
        env: options.env,
        version: options.version,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        apiVersion: options.apiVersion,
        insecureHTTP: options.insecureHTTP,
        defaultPrivacyLevel: 'allow' // 'allow' or 'mask-user-input' or 'mask'. Use one of the 3 values.
    })
    openobserveLogs.init({
        clientToken: options.clientToken,
        site: options.site,
        organizationIdentifier: options.organizationIdentifier,
        service: options.service,
        env: options.env,
        version: options.version,
        forwardErrorsToLogs: true,
        insecureHTTP: options.insecureHTTP,
        apiVersion: options.apiVersion,
    });

// You can set a user context
    openobserveRum.setUser({
        id: id,
        name: name,
        email: email
    });

    openobserveRum.startSessionReplayRecording();
}

export const O2SetUser = (id: string, name: string) => {
    openobserveRum.setUser({
        id: id,
        name: name,
    });
}