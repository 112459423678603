import {RouteRecordRaw} from "vue-router";
import {reactive} from "vue";
import LogoView from "@/components/LogoView.vue";

export default <Array<RouteRecordRaw>>[
    {
        path: '/login',
        name: 'login',
        meta: {requiresAuth: false},
        components: {
            default: () => import(/* webpackChunkName: "login" */ './LoginPage.vue'),
            tabBar: LogoView
        },
    }
]

export interface IUser {
    wx_union_id: string
    browser_id: string
    uid: number | null
    is_master: boolean
}

export interface IShop {
    phone_number: string
    sid: number
    icon: string
    shop_name: string
    tel: string
    area: string
    addr: string
    coordinate: number[]
    wx_mch_id: string | null
    wx_mch_profit_sharing_max_ratio: number | null
}

export interface IExpPackage {
    exp_package_id: string
    title: string
    times: number
    balance: number
    expire_at: Date
}

export interface IAccount {
    user: IUser | null
    shop: IShop | null
    exp_packages: IExpPackage[]
}

export const account: IAccount = reactive({user: null, shop: null, exp_packages: []})
