<template>
  <div class="block-title">
    <slot/>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="sass">
.block-title
  text-align: center
  height: 38px
  line-height: 38px
  font-weight: 700
  font-size: 22px
  color: #0e1234
  margin-bottom: 12px

</style>