import {IModule} from "@/app/editor/modules";
import {UnwrapRef, watch} from "vue";
import {DefineComponent} from "@vue/runtime-core";
import {IActivityGift, IActivityStatus} from "@/app/editor/types";
import RecommendPreview from "@/app/editor/modules/recommend/v1/RecommendPreview.vue";
import RecommendSetup from "@/app/editor/modules/recommend/v1/RecommendSetup.vue";
import {account} from "@/app/login";

export interface IDistributionRuleProps {
    require: 'none' | 'pay'
    bind: 'view' | 'order' | 'pay'
    show_list: 'hide' | 'count' | 'sum'
}

export interface IDistributionRule {
    enable: boolean
    percents: number[]
    props: IDistributionRuleProps
}

export interface IGiftRules {
    freshman_count: number
    gifts: IActivityGift[]
}

export interface IRecommendConfig {
    distribution_rule: IDistributionRule
    gift_rules: IGiftRules[]
}

export interface IRecommendModule extends IModule {
    config: UnwrapRef<IRecommendConfig>
}

export class RecommendModuleV1 implements IRecommendModule {
    readonly i: number
    readonly module = 'recommend';
    readonly version = 1;
    readonly title = '推荐设置';
    readonly icon = 'module_recommend';
    readonly preview: DefineComponent;
    readonly set_up: DefineComponent;

    config: UnwrapRef<IRecommendConfig>;

    constructor(i: number, c: UnwrapRef<IRecommendConfig>, s: IActivityStatus) {
        this.i = i
        this.preview = RecommendPreview as DefineComponent
        this.set_up = RecommendSetup as DefineComponent
        this.config = c

        // 更正
        const max = this.max()
        if (!this.config.distribution_rule) this.config.distribution_rule = {
            enable: false,
            percents: [],
            props: {require: 'none', bind: 'view', show_list: 'count'}
        }
        if (!this.distributionAvailable()) this.config.distribution_rule.enable = false
        if (this.config.distribution_rule.percents.length != 2) this.config.distribution_rule.percents = [max, 0]

        if (!this.config.gift_rules) this.config.gift_rules = []

        // 公共属性绑定
        s.is_distribution = this.config.distribution_rule.enable
        s.distribution_list = this.config.distribution_rule.props.show_list
        watch(() => this.config.distribution_rule.percents[0], () => {
            if (this.config.distribution_rule.percents[0] + this.config.distribution_rule.percents[1] > this.max()) {
                this.config.distribution_rule.percents[1] = this.max() - this.config.distribution_rule.percents[0]
            }
        })
        if (this.config.distribution_rule.enable) {
            s.rules[i] = [
                this.config.distribution_rule.props.require == 'none' ? '无门槛推荐，不需购买，推荐成功就奖励红包' : '需先购买一笔，才能享受推荐送红包福利',
                this.config.distribution_rule.props.bind == 'view' ? '打开分享人链接即成为下级' : this.config.distribution_rule.props.bind == 'order' ? '首次报名成功时绑定为分享人的下级' : '首次购买成功时绑定为分享人的下级'
            ]
        }
        watch(() => this.config.distribution_rule.enable, v => {
            s.is_distribution = v
            if (v) {
                s.rules[i] = [
                    this.config.distribution_rule.props.require == 'none' ? '无门槛推荐，不需购买，推荐成功就奖励红包' : '需先购买一笔，才能享受推荐送红包福利',
                    this.config.distribution_rule.props.bind == 'view' ? '打开分享人链接即成为下级' : this.config.distribution_rule.props.bind == 'order' ? '首次报名成功时绑定为分享人的下级' : '首次购买成功时绑定为分享人的下级'
                ]
            } else {
                s.rules[i] = []
            }
        })
        watch(() => this.config.distribution_rule.props.show_list, v => s.distribution_list = v)
        watch(() => this.config.distribution_rule.props, p => {
            if (this.config.distribution_rule.enable) {
                s.rules[i] = [
                    p.require == 'none' ? '无门槛推荐，不需购买，推荐成功就奖励红包' : '需先购买一笔，才能享受推荐送红包福利',
                    p.bind == 'view' ? '打开分享人链接即成为下级' : p.bind == 'order' ? '首次报名成功时绑定为分享人的下级' : '首次购买成功时绑定为分享人的下级'
                ]
            }
        }, {deep: true})
    }

    validate(): Error | null {
        return null;
    }

    max(): number {
        return Math.floor((account.shop?.wx_mch_profit_sharing_max_ratio ?? 3000) / 100) - 1
    }

    distributionAvailable(): boolean {
        return !!account.shop?.wx_mch_id && !!account.shop.wx_mch_profit_sharing_max_ratio
    }
}