import {RouteRecordRaw} from "vue-router";
import TabBarView from "@/components/TabBarView.vue";
import TemplatesSide from "@/app/templates/TemplatesSide.vue";
import dayjs, {Dayjs} from "dayjs";
import TemplatesPanelView from "@/app/templates/TemplatesPanelView.vue";

export default <Array<RouteRecordRaw>>[
    {
        path: '/',
        name: 'templates',
        meta: {requiresAuth: true},
        components: {
            default: TemplatesPanelView,
            side: TemplatesSide,
            tabBar: TabBarView,
        },
        beforeEnter: (to, _, next) => {
            if (!to.query.industry && window.localStorage.industry) {
                to.query.industry = window.localStorage.industry
                console.log(to.query)
            }
            return next()
        },
        children: []
    }
]

export const tagsFestival = {
    'j_nsj': '女神节',
    "j_wyldj": "劳动节",
    'j_dwj': '端午节',
    'j_618': '618',
    'j_zqj': '中秋节',
    'j_gqj': '国庆节',
    'j_ssy': '双11',
    'j_1212': '双12',
    'j_yd': '元旦',
    'j_mqj': '母亲节',
    'j_sdj': '圣诞节',
    'j_gej': '感恩节',
    't_nzdc': '年中大促',
}
export const tagsFestivalDate: { [key: string]: Dayjs } = {
    // 'j_wsj': dayjs('2023-11-01', 'YYYY-MM-DD'),
    // 'j_cjzs': dayjs('2024-03-31', 'YYYY-MM-DD'),
    // 'j_zsj': dayjs('2024-03-12', 'YYYY-MM-DD'),
    't_nzdc': dayjs('2024-08-20', 'YYYY-MM-DD'),
    'j_zqj': dayjs('2024-09-17', 'YYYY-MM-DD'),
    'j_gqj': dayjs('2024-10-01', 'YYYY-MM-DD'),
    'j_ssy': dayjs('2024-11-11', 'YYYY-MM-DD'),
    'j_gej': dayjs('2024-11-28', 'YYYY-MM-DD'),
    'j_1212': dayjs('2024-12-12', 'YYYY-MM-DD'),
    'j_sdj': dayjs('2024-12-25', 'YYYY-MM-DD'),
    'j_yd': dayjs('2025-01-01', 'YYYY-MM-DD'),
    'j_nsj': dayjs('2025-03-08', 'YYYY-MM-DD'),
    'j_wyldj': dayjs('2025-05-01', 'YYYY-MM-DD'),
    'j_mqj': dayjs('2025-05-11', 'YYYY-MM-DD'),
    'j_dwj': dayjs('2025-05-31', 'YYYY-MM-DD'),
    'j_618': dayjs('2025-06-18', 'YYYY-MM-DD'),
}
export const tagsModel: { [key: string]: string } = {
    'm_ptt': '普通团',
    'm_jgjtt': '价格阶梯团',
    'm_spjtt': '商品阶梯团',
}
export const tagsTool = {}
export const tagsToolCard = {
    ...tagsTool,
    'g_fx': '分销',
}

export interface IIndustryGroup {
    name: string
    tags: string[]
}

export const tagsIndustryGroup: { [key: string]: IIndustryGroup } = {
    'tg_sejp': {
        name: '少儿教培',
        tags: ['t_sems', 't_sesf', 't_sezc', 't_sekc', 't_sewd', 't_semt', 't_sety', 't_seyq', 't_selg', 't_sebc']
    },
    'tg_lrmy': {name: '丽人美业', tags: ['t_mr', 't_mf', 't_tdt', 't_mj', 't_cr']},
    'tg_cy': {name: '餐饮小吃', tags: ['t_sk', 't_jcc', 't_mx', 't_lsf', 't_mlt', 't_hg', 't_dg', 't_nc']},
    'tg_qt': {name: '更多行业', tags: ['t_zbhj', 't_hssy', 't_zy', 't_xc']},
    // 'tg_zbhj': {name: '珠宝黄金', tags: ['t_zbhj']},
    // 'tg_hssy': {name: '婚纱摄影', tags: ['t_hssy']},
    // 'tg_zy': {name: '足浴', tags: ['t_zy']},
    // 'tg_xc': {name: '洗车', tags: ['t_xc']},
}
export const tagsIndustry: { [key: string]: string } = {
    't_sems': '少儿美术',
    't_sesf': '少儿书法',
    't_sezc': '少儿主持',
    't_sekc': '少儿口才',
    't_sewd': '少儿舞蹈',
    't_semt': '少儿模特',
    't_sety': '少儿体育',
    't_seyq': '少儿乐器',
    't_selg': '少儿乐高',
    't_sebc': '少儿编程',
    't_mf': '美发',
    't_mr': '美容',
    't_tdt': '头道汤',
    't_sk': '烧烤',
    't_jcc': '家常菜',
    't_mx': '米线',
    't_lsf': '螺蛳粉',
    't_mlt': '麻辣烫',
    't_hg': '火锅',
    't_zbhj': '珠宝黄金',
    't_hssy': '婚纱摄影',
    't_dg': '甜点',
    't_nc': '奶茶',
    't_mj': '美甲',
    't_zy': '足浴',
    't_cr': '采耳',
    't_xc': '洗车'
}
export const tagsTarget = {
    't_xdky': '新店开业',
    't_znqd': '周年庆典',
    // 't_nzdc': '年中大促',
    // 't_bgyl': '曝光引流',
}

export const cardTags = {...tagsFestival, ...tagsModel, ...tagsToolCard, ...tagsIndustry, ...tagsTarget}

export interface ITemplate {
    template_id: string
    relation_activity_id: string
    title: string
    poster: string
    tags: string[]
    create_at: number
    use_count: number
    is_active: boolean
    theme_count?: number
}