<template>
  <a-col :span="4" class="key">
    <slot/>
    ：
  </a-col>
</template>

<script setup lang="ts">

</script>

<style scoped lang="sass">
.key
  text-align: right
  font-size: 16px
  line-height: 22px
</style>