declare global {
    interface Array<T> {
        last(): T | undefined
        first(): T | undefined
    }
}
if (!Array.prototype.last) {
    Array.prototype.last = function <T>(): T | undefined {
        return this.slice(-1).pop()
    }
}
if (!Array.prototype.first) {
    Array.prototype.first = function <T>(): T | undefined {
        return this.length > 0 ? this.slice(0,1).shift(): undefined
    }
}
export {}